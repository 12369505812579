<template>
  <!-- Desc: View: search terms for each adgroup -->
  <div
    class="card min-height-card u-flex-direction-column u-width-100 u-display-flex"
  >
    <div>
      <addSearchTermToKeywordPanel
        v-if="showKeywordsPanel"
        :selected-values="selections"
        :campaign-edit-config="campaignEditConfig"
        :show-campaign-details="false"
        :selected-action-type="selectedActionType"
        @closePanelMethod="closeActionBar"
        @afterAPIActionToCall="closeActionBar"
        @callApiFromCampaignEdit="preparekeywordPayload"
      />
      <addNegativesPanel
        v-if="showNegativePanel"
        :selected-values="selections"
        :title="'Add Negative Keywords'"
        :campaign-edit-config="campaignEditConfig"
        :show-campaign-details="false"
        :selected-action-type="selectedActionType"
        @closePanelMethod="closeActionBar"
        @afterAPIActionToCall="closeActionBar"
        @callApiFromCampaignEdit="preparekeywordPayload"
      />
    </div>
    <div
      v-if="showActionsBar"
      class="u-spacing-pt-l"
    >
      <actions-bar
        :close-fn="closeActionBar"
        :selections="selections"
        :recommendation-type="''"
        :passed-actions="allowedActions"
        @open-workspace="openActionsWorkspace"
      />
    </div>
    <tableWrapper
      :table-data-component-key="tableDataComponentKey"
      :class="selections.length && 'campaign_details_table_header_padding'"
      :date-filter="dateFilter"
      :tab-metadata="tabMetadata"
      :widget="widget"
      :header-options="headerOptions"
      :context="context"
      :entity-config="entityConfig"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import { cloneDeep } from 'lodash';
import addNegativesPanel from '@/components/ams/actionPanels/addNegativesPanel.vue';
import addSearchTermToKeywordPanel from '@/components/ams/actionPanels/addSearchTermAsKeywordPanel.vue';
const tableWrapper = () =>
  import('@/pages/entity_details/common_components/tableWrapper.vue');
const actionsBar = () => import('@/components/widgets/actionsBar.vue');
const footerButtons = () =>
  import('@/pages/entity_details/common_components/footerButtons.vue');
const iconTextActions = () =>
  import('@/components/widgets/iconTextActions.vue');

export default {
  components: {
    tableWrapper,
    actionsBar,
    footerButtons,
    addNegativesPanel,
    addSearchTermToKeywordPanel
  },
  props: {
    dateFilter: {
      default: () => {},
      type: Object
    },
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    },
    parentEntityId: {
      default: null,
      type: String
    }
  },
  data() {
    const context = this;
    return {
      getState: null,
      selections: [],
      showKeywordsPanel: false,
      showNegativePanel: false,
      showActionsBar: false,
      stateChangeRequestPayload: null,
      bidChangeRequestPayload: null,
      updateMetadata: null,
      tableGridAPI: null,
      addKeywordPayload: null,
      myKey: 1,
      addNegativeKeywordPayload: null,
      context,
      actionIdFunctionMap: {
        addAsKeyword: this.toggleAddKeywordPanel,
        addAsNegativeKeyword: this.toggleNegativePanel
      },
      widget: {},
      allowedActions: [
        {
          icon: 'add-circle-fill',
          title: 'Add as Keyword',
          id: 'addAsKeyword'
        },
        {
          icon: 'remove-fill',
          title: 'Add as Negative Keyword',
          id: 'addAsNegativeKeyword'
        }
      ],
      campaignEditConfig: {
        boolCampaignEdit: true
      },
      tabConfig: null,
      selectedActionType: null
    };
  },
  computed: {
    tableDataComponentKey() {
      return this.$store.getters[
        this.tabConfig?.apiConfigs?.getTableDataComponentKeyGetter
      ];
    },
    campaignInfo() {
      const campaignInfoApiConfigKey =
        this.tabConfig?.apiConfigs?.campaignGetter;
      return this.$store.getters[campaignInfoApiConfigKey];
    },
    adgroupInfo() {
      return this.$store.getters[this.getState];
    },
    headerOptions() {
      const obj = {};
      if (this.tabMetadata?.widgets[0]) {
        obj[this.tabMetadata.widgets[0]] = {
          props: {
            // enableDownload: true,
            enableManageColumns: !this.selections.length,
            manageColumnGridPosition: 4,
            headerContainerGridStyles: {
              'grid-template-columns': 'auto 1fr auto',
              display: this.selections.length ? 'none' : 'grid'
            },
            searchParams: {
              show: true,
              searchDimension: 'ams_cm_searchterm_search_term',
              placeholder: 'Find a search term'
            },
            rowSelection: {
              mode: 'multiple',
              onSelectionChanged: this.handleSelectionChange
            },
            rowHeight: 45
          }
        };
      }
      return obj;
    }
  },
  created() {
    Vue.component('iconTextActions', iconTextActions);
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.getState = tabConfig?.apiConfigs?.getter;
    this.stateChangeRequestPayload = tabConfig?.stateChange?.requestPayload;
    this.bidChangeRequestPayload = tabConfig?.bidChange?.requestPayload;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    this.addKeywordPayload = tabConfig?.addAsKeywordPayload?.requestPayload;
    this.addNegativeKeywordPayload =
      tabConfig?.addAsNegativeKeywordPayload?.requestPayload;
    this.widget = {
      ':widget': this.tabMetadata.widgets[0],
      ':orderByList': [
        {
          dimension: 'ams_cm_searchterm_spend',
          direction: 'DESC'
        }
      ],
      ':dimensionNameValueList': [],
      keepExistingDimensionNameValueList: true,
      replaceEntityInDimensionNameValueList: {
        ':adgroup_id': this.entityId
      }
    };
  },
  methods: {
    handleSelectionChange(item1, item2) {
      this.tableGridAPI = item2?.api;
      this.selections = item1;
      if (this.selections.length) {
        if (this.showKeywordsPanel) {
          this.showNegativePanel = false;
          this.showActionsBar = false;
        } else if (this.showNegativePanel) {
          this.showKeywordsPanel = false;
          this.showActionsBar = false;
        } else {
          this.showActionsBar = true;
        }
      } else {
        this.closeActionBar();
      }
    },
    openActionsWorkspace(actionId) {
      this.selectedActionType = actionId;
      this.prepareCampaignData();
      this.showActionsBar = false;
      this.actionIdFunctionMap[actionId]();
    },
    closeActionBar() {
      this.tableGridAPI?.deselectAll();
      this.selections = [];
      this.toggleAddKeywordPanel(true);
      this.showActionsBar = false;
      this.prepareCampaignData();
      this.toggleNegativePanel(true);
    },
    toggleAddKeywordPanel(close) {
      var elm = document.getElementsByClassName('campaign-tab-container');
      if (close === undefined) {
        this.showKeywordsPanel = true;
        this.shmowActionsBar = false;
        elm[0].scrollTo(0, 0);
        return;
      }
      this.showKeywordsPanel = false;
      elm[0].scrollTo(0, 200);
    },
    toggleNegativePanel(close) {
      var elm = document.getElementsByClassName('campaign-tab-container');
      if (close === undefined) {
        this.showNegativePanel = true;
        this.showActionsBar = false;
        elm[0].scrollTo(0, 0);
        return;
      }
      this.showNegativePanel = false;
      elm[0].scrollTo(0, 500);
    },
    prepareCampaignData() {
      const allCampaingsData = [
        {
          campaign_name: this.campaignInfo?.data?.name || 'NA',
          adgroup_id: this.entityId,
          campaignType: this.entityConfig.entityType.toLowerCase(),
          adgroup_name: this.adgroupInfo?.adgroup_name || 'NA',
          campaign_id: this.parentEntityId,
          selected: true
        }
      ];
      this.campaignEditConfig.allCampaingsData = allCampaingsData;

      this.selections.map((item, index) => {
        this.selections[index].data.bid = item.data.ams_cm_searchterm_bid;
        this.selections[index].data.campaign_type =
          this.entityConfig.entityType;
        this.selections[index].data.keyword_id =
          item.data.ams_cm_searchterm_keyword_id;
        this.selections[index].data.search_term =
          item.data.ams_cm_searchterm_search_term;
        this.selections[index].data.keyword_text =
          item.data.ams_cm_searchterm_keyword_text;
        this.selections[index].data.match_type_keyword =
          item.data.ams_cm_searchterm_match_type_keyword;
        this.selections[index].data.keyword_type =
          item.data.ams_cm_searchterm_keyword_type;
        this.selections[index].data.clicks = item.data.ams_cm_searchterm_clicks;
        this.selections[index].data.spend = item.data.ams_cm_searchterm_spend;
        this.selections[index].data.orders_14d =
          item.data.ams_cm_searchterm_orders_14d;
        this.selections[index].data.paid_sales_14d =
          item.data.ams_cm_searchterm_paid_sales_14d;
        this.selections[index].data.acos_14 =
          item.data.ams_cm_searchterm_acos_14d;
      });
    },
    preparekeywordPayload(objData) {
      const payloadArr = [];
      for (const selection of objData.selections) {
        const payload =
          objData.action === 'addAsKeyword'
            ? cloneDeep(this.addKeywordPayload)
            : cloneDeep(this.addNegativeKeywordPayload);
        const reqValues = {
          ':adgroupId': this.entityId,
          ':campaignId': this.parentEntityId,
          ':pageUrl': window.location.href,
          ':keywordText': selection?.actionPayload?.keywordText,
          ':matchType': selection?.actionPayload?.matchType,
          ':bid': selection?.actionPayload?.newBid,
          ':adgroupName': this.adgroupInfo?.adgroup_name || 'NA'
        };
        for (const item in reqValues) {
          deepReplaceObjectValues(payload, item, reqValues[item]);
        }
        payloadArr.push(payload);
      }
      let entityName = {
        singular: 'Search Term',
        plural: 'Search Terms',
        stopFetchApi: true
      };
      if (objData.action === 'addAsNegativeKeyword') {
        entityName = {
          singular: 'Negative Keyword',
          plural: 'Negative Keywords',
          action: 'added',
          stopFetchApi: true
        };
      }
      this.updateMetadataAction(payloadArr, entityName);
    },
    updateMetadataAction(payloadArr, entityName) {
      this.$store.dispatch(this.updateMetadata, {
        updateRequest: payloadArr,
        entityId: this.parentEntityId,
        entityType: this.entityConfig.entityType,
        snackbar: this.$snackbar,
        callAdgroupFetch: false,
        entityName
      });
      this.closeActionBar();
      this.myKey++;
    }
  }
};
</script>

<style lang="css" scoped>
.min-height-card {
  min-height: 630px;
}
</style>

<style lang="css">
.campaign_details_table_header_padding .header-container-cwc {
  padding: 0px;
}
</style>
